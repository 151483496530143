import React from "react";
import "./yoga.css";
import beach from "../pictures/beach.jpg";

export class Yoga extends React.Component {
    render() {
        return(
            <div className="row">
                <div className="col-md-12">
                    <p className="header" id="yoga">Yoga by Sara Gonzalez</p>
                        <p className="headline1">
                            Hoy día debido a la velocidad de la vida en que vivímos , la competitividad diaria y la masificación que causan los efectos del estrés, nuestro cuerpo se lesiona y
                            cae enfermo. Todo esto provoca sensación de estar insatisfechos, agitados e infelices. Con la práctica de yoga descubrimos como calmar estas desarmonías, aquietarlas
                            y sobreponernos a ellas mediante estiramientos y ejercicios suaves guiados por una respiración lenta y rítmica, tranquilizando la mente como técnicas como la visualización
                            y la meditación.</p>
                            <br/>

                        <h1 className="headline"> ¿Porqué Yoga?</h1>
                                <ul className="text">
                                    <li>La palabra Yoga significa unión. La unión entre cuerpo -mente - alma (espíritu).</li> 
                                    <li>Esta disciplina nos ayuda a alcanzar y elevar nuestra consciencia obteniendo paz interior y armonía.</li>   
                                    <li>El camino de esta, es una búsqueda profunda, una búsqueda personal para llegar a un mayor conocimiento.</li> 
                                    <li>Un viaje espiritual hacia nuestro interior y el más allá intentando una unidad, la unión espiritual con la consciencia universal.</li>
                                    <li>El propósito de esta disciplina oriental es el equilibrio entre cuerpo-mente proporcionando un estado de salud física y bienestar, paz y serenidad.
                                    Por eso lo comparamos como un arte curativo, el cual elimina bloqueos y que pueden impedir el avance por el camino hacia la totalidad y comprensión universal.
                                    Se trata de una actitud, una especie de espiritualidad que mediante unas determinadas posturas y pranayamas (ejercicios de respiración) nos conduce a una forma
                                    de vida armoniosa y equilibrada.</li>
                                    <li>He de recordar que no existe un único tipo de yoga, ni que todas las posturas que en él se practican son inmutables puesto que en algunas de ellas tienen 
                                    diferentes variables.</li>
                                </ul>     
                    </div>

                    {/* <div className="col-md-3">
                         <img className="picture" src={beach}/>
                    </div> */}
                
                    
            </div>    
            

        )
    }
}

export default Yoga;