import React from "react";
import './bhakti.css';
import pose from "../../pictures/pose.jpg";


export class Bhakti extends React.Component {
    render() {
        return(
            <div>        
                <div className="row">           
                        <div className="col-md-7">
                          <h1 className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</h1> <br/>
                             <h2 className="headline">BHAKTI YOGA</h2>
                                 <p className="text">Bhakti significa devoción a un dios o divinidad. La idea fundamental de esta senda es proyectar esta devoción hacia 
                                 una divinidad concreta sin pedir nada a cambio, en forma de entrega absoluta a ella. Podríamos decir que la mayoría de religiones llegan a entenderse
                                como devocionales.</p>    
                        </div> 

                        <div className="col-md-1"></div>

                        <div className="col-md-4">
                            <img className="picture" src={pose}/>
                        </div>                       
                </div>
            </div>
        
        )
    }
}

export default Bhakti;