import React from "react";
import './hatha.css';
import hands from "../../pictures/hands.jpg";


export class Hatha extends React.Component {
    render() {
        return(            
            <div>
                <div className="row">
                    <div className="col-md-7">        
                          <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                             <p className="headline">HATHA YOGA</p>
                                <p className="text">Hatha significa sol y luna, y por lo tanto Hatha Yoga representa la unión del sol y la luna, que simbolizan la fuerza vital y la
                                fuerza mental. Este sendero busca la realización del sí mismo mediante el fortalecimiento del cuerpo, que es mucho más asequible que la mente, para que se 
                                transmute en cuerpo divino y le permita alcanzar la liberación. El Hatha Yoga se compone de una serie de prácticas, que han de conseguir el despertar de
                                la Kundalini y llevar al adepto a la supraconciencia.</p> 
                                    <p className="text">Podemos agrupar estas prácticas de la siguiente forma:</p>
                        <ul className="text">
                            <li>Prácticas de purificación (shatkarmas)</li>
                            <li>Posturas (asanas)</li>
                            <li>Llaves energéticas (bhandas)</li>
                            <li>Sellos o gestos (mudras)</li>
                            <li>Dominio de la respiración (pranayamas)</li>
                        </ul>   
                        <p className="text">NO hay que confundir el Hatha Yoga con ejercicios de estiramientos o con una actividad deportiva o terapéutica ,
                        ya que eso sería simplificar mucho su auténtico contenido.</p> 
                    </div>
                  
                <div className="col-md-1"></div>
                  
                 <div className="col-md-4">
                    <img className="picture" src={hands}/>
                </div> 
            </div>
        </div>  
        )
    }
}

export default Hatha;