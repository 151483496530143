import React from "react";
import './App.css';
import NavBar from "../src/components/navBar/navBar";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Yoga from "../src/components/yoga/yoga";
import Arbol from "../src/components/arbol/arbol";
import Karma from "./components/drops/karma/karma";
import Raja from "./components/drops/raja/raja";
import Bhakti from "./components/drops/bhakti/bhakti";
import Jñana from "./components/drops/jñana/jñana";
import Dhayana from "./components/drops/dhayana/dhayana";
import Hatha from "./components/drops/hatha/hatha";
import Japa from "./components/drops/japa/japa";
import Kundalini from "./components/drops/kundalini/kundalini";
import Laya from "./components/drops/laya/laya";
import Mantra from "./components/drops/mantra/mantra";
import Tantra from "./components/drops/tantra/tantra";
import Otras from "./components/drops/otras/otras";
import Beneficios from "./components/beneficios/beneficios";
import Contact from "./components/contact/contact";
import Galeria from "./components/galeria/galeria";
import Home from "./components/home/home";
import pose1 from "../src/components/pictures/pose1.jpg";
import phone from "../src/components/pictures/phone.png";
import { ImPhone } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import Footer from "../src/components/footer/footer";

export class App extends React.Component {
  render() {
    return (
      <div className="">
          <header>
              <div className="contacto">
                <div className="phone">
                    <ImPhone className="icon"/>
                    <a href="tel:+34 638 89 22 21" className="info email">+34 638 89 22 21</a> 
                 </div> 
                 <div className="mail">
                    <MdEmail className="icon2"/>
                    <a href="mailto:saragg3909@gmail.com" className="info email">saragg3909@gmail.com</a>
                 </div>
             </div>
               <NavBar/>
          </header>          

          <div className="App-content">   
                    <Router>        
                        <Switch>
                            <Route path="/yoga" component={Yoga} />
                            <Route path="/karma" component={Karma} />
                            <Route path="/raja" component={Raja} />
                            <Route path="/bhakti" component={Bhakti} />
                            <Route path="/jñana" component={Jñana} />
                            <Route path="/dhayana" component={Dhayana} />
                            <Route path="/hatha" component={Hatha} />
                            <Route path="/japa" component={Japa} />
                            <Route path="/kundalini" component={Kundalini} />
                            <Route path="/laya" component={Laya} />
                            <Route path="/mantra" component={Mantra} />
                            <Route path="/tantra" component={Tantra} />
                            <Route path="/otras" component={Otras} />
                            <Route path="/beneficios" component={Beneficios} />
                            {/* <Route path="/contact" component={Contact} /> */}
                            <Route path="/galeria" component={Galeria} />  
                            <Route path="/" component={Home} />  
                        </Switch>      
                      </Router>  
            </div>     
            {/* <Footer/>                */}
        </div>                 
    
    );
  }
}

export default App;
