import React from "react";
import './tantra.css';
import pillow1 from "../../pictures/pillow1.png";


export class Tantra extends React.Component {
    render() {
        return(
            <div>        
                <div className="row">
                     <div className=" col-md-7">
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">TANTRA YOGA</p>
                                <p className="text">Tantra significa tejido, es una doctrina que apareció en la India en el siglo IV. Combina filosofía, esoterismo y espiritualidad.
                                Es el promotor del cambio que sustituye la idea de un cuerpo como carga o lastre por la visión tántrica del cuerpo como morada sagrada que hay que cuidar.</p>    
                </div>   

                <div className="col-md-1"></div>     

                 <div className="col-md-4">
                    <img className="picture" src={pillow1}/>
                </div> 
             </div>
        </div>
        
        )
    }
}

export default Tantra;