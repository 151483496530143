import React from "react";
import './japa.css';
import beachfront from "../../pictures/beachfront.jpg";


export class Japa extends React.Component {
    render() {
        return(            
            <div>
                <div className="row">
                    <div className="col-md-7">        
                          <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">JAPA YOGA</p>
                                <p className="text">Japa significa repetir en voz baja e internamente. El Japa Yoga consiste fundamentalmente en la repetición de cualquier mantra
                                nombre del señor o dios, y se trata de un práctica muy antigua. La recitación debe de seguir una metodología concreta para que el mantra libere todo lo contenido en él.
                                Dá un especial valor al nombre y otorga mucha importancia a estar atentos al significado mientras se recita el mantra.</p> <br/>
                    </div> 

            <div className="col-md-1"></div>

            <div className="col-md-4">
                    <img className="picture" src={beachfront}/>
                </div>
            </div>   
        </div>

        )
    }
}

export default Japa;