import React from "react";
import "./footer.css";
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText, Link} from 'reactstrap';
import logo_NY from "../pictures/logo_NY.png";

export class Footer extends React.Component {
    render() {
        return (
            <div className="fot">
            </div>
        )
    }
}

export default Footer;