import React from "react";
import "./arbol.css";
import tree from "../pictures/tree.jpg";

export class Arbol extends React.Component {
    render() {
        return(
            <div>
                <div className="picture">
                    <img className="tree" src={tree}/>
                </div>
                <div className="info">
                    <h1 className="header">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</h1> <br/>
                </div>
            </div>


        )
    }
}

export default Arbol;