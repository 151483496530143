import React from "react";
import './raja.css';
import poserocks from "../../pictures/poserocks.jpg";


export class Raja extends React.Component {
    render() {
        return(            
            <div>           
                <div className="row">   
                    <div className="col-md-7">     
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                             <p className="headline">RAJA O ASTHANGA YOGA</p>
                                <p className="text">Raja Yoga significa yoga real o de los reyes y también se denomina Asthanga Yoga refiriéndose a camino real de las ocho partes.
                                Para conseguir la quietud mental, afloramiento de sí mismo y la absorción de la realidad espiritual, propone como indispensable seguir una serie de fases 
                                realizando una ruta que presenta ciertas características;</p> <br/>

                    <div className="text">
                        <ul>
                            <li>Las dos primeras fases, consideradas como preparatorias, son yamas y niyamas, y son unos preceptos éticomorales y deberes que se estudian
                            al principio y que nos debe acompañar en toda la práctica.</li>
                            <li>Las siguientes tres fases son asanas, pranayamas y pratyahara. Guardan relación con el dominio físico. Asanas, significa posturas y en los
                            inicios del Raja Yoga se centraban especialmente en favorecer la meditación. Los pranayamas ,son técnicas respiratorias.</li>
                            <li>Pratyahara, consiste en que la mente se aisle del sistema sensorial. Las tres últimas fases están relacionadas con dominio mental. 
                            Y deben aprenderse en un orden concreto, ya que para asumir una es necesario haber superado la anterior. Dharana es la concentración; Dhayana es 
                            la meditación es un estado inicial y Samadhi, la etapa final en una fase de meditación que lleva a la interiorización profunda y permite transcender la 
                            experiencia ordinaria.</li>
                        </ul>
                    </div>  
                </div>

                    <div className="col-md-1"></div>

                    <div className="col-md-4">
                            <img className="picture" src={poserocks}/>
                    </div>                                           
                </div>  
            </div>
        )
    }
}

export default Raja;