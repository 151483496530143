import React from "react";
import './kundalini.css';
import poseocean from "../../pictures/poseocean.jpg";


export class Kundalini extends React.Component {
    render() {
        return(            
            <div>
                <div className="row">
                     <div className="col-md-7">        
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline ">KUNDALINI YOGA</p>
                                <p className="text">La Kundalini mora en los Chakras inferiores y el practicante ha de favorecer su ascenso, paso a paso , chakra a chakra, hacia la parte 
                                superior de la cabeza, al loto del mil pétalos (chakra superior). Cada chakra es un peldaño que hay que ir despertando, hay que seguir la cadena sin saltarse ninguno.
                                Cuando uno despierta liberándose de residuos e impurezas, permite que la Kundalini ascienda a él. La llegada al último chakra significa la fusión del principio 
                                femenino con el masculino que habita en el chackra superior. A esto se le llama el despertar de la Kundalini que produce un éxtasis repleto de sensaciones físicas.
                                Las herramientas son parecidas a las del Hatha Yoga: asanas, pranayamas, mudras, bhandas, pero siempre enfocadas a purificar un chakra en concreto.</p>
                </div>   

                <div className="col-md-1"></div>  

                <div className="col-md-4">
                    <img className="picture" src={poseocean}/>
                </div>
            </div>
        </div>  
        )
    }
}

export default Kundalini;