import React from "react";
import './karma.css';
import tree from "../../pictures/tree.jpg";
import sunset_NY from "../../pictures/sunset_NY.jpg";

export class Karma extends React.Component {
    render() {
        return(
            <div>               
                 <div className="row">
                    <div className="col-md-7">
                        <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">KARMA YOGA</p>
                                <p className="text">Karma significa acción, pero en general se entiende como los efectos de las acciones.
                                Toda acción produce un efecto que se convierte a su vez en causa, para finalmente regresar a la fuente que lo ha generado.
                                Para el Karma Yoga lo importante es lo cotidiano, vivir en un permanente ahora, donde cada circunstancia es un todo que ha de vivirse con total entrega.
                                Esta senda no se centra en juzgar las acciones como buenas o malas , sino que enseña a como liberarse en la vida activa.</p>    
                    </div> 

                    <div className="col-md-1"></div>
                    
                    <div className="col-md-4">
                        <img className="picture" src={tree}/>
                    </div>       
                </div>
             </div>
        
        )
    }
}

export default Karma;