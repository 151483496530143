import React from "react";
import './jñana.css';
import ontree from "../../pictures/ontree.jpg";


export class Jñana extends React.Component {
    render() {
        return(            
            <div>               
                <div className="row">
                    <div className="col-md-7">        
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">JÑAN YOGA</p>
                                 <p className="text">El Jñana Yoga se puede traducir como el sendero del conocimiento y la subiduría. Lo que no se ha experimentado por uno mismo 
                                no debe creerse y por tanto no puede aceptarse lo implícito; hay que eliminar todo acondicionamiento, hay que desaprender para poder tener acceso al conocimiento
                                profundo desde la experiencia directa. Un camino a la realización mediante la comprensión gnóstica, que se centra especialmente en una búsqueda del puro conocimiento
                                de la naturaleza y de lo temporal.</p>
                      </div>

                <div className="col-md-1"></div>

                <div className="col-md-4">
                    <img className="picture" src={ontree}/>
                </div>            
            </div>        
        </div>

        )
    }
}

export default Jñana;