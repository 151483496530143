import React from "react";
import './mantra.css';
import balance1 from "../../pictures/balance1.png";


export class Mantra extends React.Component {
    render() {
        return(
            <div>        
                <div className="row">
                     <div className="col-md-7">
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">MANTRA YOGA</p>
                                <p className="text">El Mantra Yoga, usa el poder del sonido y la vibración que este emite para purificar la mente y el cuerpo.
                                Puede ser una palabra, sílaba o frase, cuya pronunciación emite un sonido concreto, como ocurre con el mantra Om (Aum). Está encerrado en su poder creativo y en 
                                su acción directa sobre la conciencia.</p>    
                </div>    

                <div className="col-md-1"></div>    

                 <div className="col-md-4">
                    <img className="picture" src={balance1}/>
                </div>
            </div>
        </div>
        
        )
    }
}

export default Mantra;