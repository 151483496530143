import React from "react";
import "./galeria.css";
import back from "../pictures/back.jpg";
// import balance1 from "../pictures/balance1.jpg";
// import rock1 from "../pictures/rock1.jpg";
import ontree from "../pictures/ontree.jpg";
import top from "../pictures/top.jpg";
import beachfront from "../pictures/beachfront.jpg";
import hands from "../pictures/hands.jpg";
import pose from "../pictures/pose.jpg";
import poseocean from "../pictures/poseocean.jpg";
// import pillow1 from "../pictures/pillow1.jpg";
import legs from "../pictures/legs.jpg";
import tree from "../pictures/tree.jpg";
import poserocks from "../pictures/poserocks.jpg";
import dog2 from "../pictures/dog2.jpg";
import up_down from "../pictures/up_down.jpg";


export class Galeria extends React.Component {
    render() {
        return(
            
            <div className="row"> 
                 <div className="gallery">   
                    <div className="col-md-12">
                        <p className="header" id="galeria">Galeria</p>
                    </div>
                  
                     <div>
                        <img className="picture1" src={back}/>
                    </div>
                    {/* <div className="">
                        <img className="picture1" src={balance1}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={rock1}/>
                    </div> */}
                    <div className="">
                        <img className="picture1" src={ontree}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={top}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={beachfront}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={hands}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={pose}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={poseocean}/>
                    </div>
                    {/* <div className="">
                        <img className="picture1" src={pillow1}/>
                    </div> */}
                    <div className="">
                        <img className="picture1" src={legs}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={tree}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={poserocks}/>
                    </div>
                    <div className="">
                        <img className="picture1" src={dog2}/>
                    </div> 
                    <div className="">
                        <img className="picture1" src={up_down}/>
                </div>   
            </div>                 

        </div>
        
        )
    }
}

export default Galeria;