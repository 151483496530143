import React from "react";
import "./home.css";
import NavBar from "../navBar/navBar";
import Arbol from "../arbol/arbol";
import Yoga from "../yoga/yoga";
import Beneficios from "../beneficios/beneficios";
import { Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import back from "../pictures/back.jpg";
import ontree from "../pictures/ontree.jpg";
import top from "../pictures/top.jpg";
import poseocean from "../pictures/poseocean.jpg";
import balance1 from "../pictures/balance1.png";
import poserocks from "../pictures/poserocks.jpg";
import hands from "../pictures/hands.jpg";
import Galeria from "../galeria/galeria";



import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class Home extends React.Component {
    constructor(props) {
        super (props);
    
        this.toggle = this.toggle.bind(this);

        this.state = {
                modal: "",
                modalContent: "",
            };
        }

        toggle(toggleValue) {
            let returnValue = "";
            switch(toggleValue) {

                case 0:
                    returnValue = <div><h2>KARMA YOGA</h2>
                                    Karma significa acción, pero en general se entiende como los efectos de las acciones.
                                    Toda acción produce un efecto que se convierte a su vez en causa, para finalmente regresar a la fuente que lo ha generado.
                                    Para el Karma Yoga lo importante es lo cotidiano, vivir en un permanente ahora, donde cada circunstancia es un todo que ha de vivirse con total entrega.
                                    Esta senda no se centra en juzgar las acciones como buenas o malas , sino que enseña a como liberarse en la vida activa.</div>
                                    break;
                case 1:
                    returnValue = <div><h2>RAJA YOGA</h2>
                                    Raja Yoga significa yoga real o de los reyes y también se denomina
                                    Asthanga Yoga refiriéndose a camino real de las ocho partes.
                                    Para conseguir la quietud mental, afloramiento de sí mismo y la absorción de la realidad espiritual, propone como indispensable seguir una serie de fases 
                                    realizando una ruta que presenta ciertas características; <br/><br/>
                                        <ul className="text">
                                            <li>Las dos primeras fases, consideradas como preparatorias, son yamas y niyamas, y son unos preceptos éticomorales y deberes que se estudian
                                            al principio y que nos debe acompañar en toda la práctica.</li>
                                            <li>Las siguientes tres fases son asanas, pranayamas y pratyahara. Guardan relación con el dominio físico. Asanas, significa posturas y en los
                                            inicios del Raja Yoga se centraban especialmente en favorecer la meditación. Los pranayamas ,son técnicas respiratorias.</li>
                                            <li>Pratyahara, consiste en que la mente se aisle del sistema sensorial. Las tres últimas fases están relacionadas con dominio mental. 
                                            Y deben aprenderse en un orden concreto, ya que para asumir una es necesario haber superado la anterior. Dharana es la concentración; Dhayana es 
                                            la meditación es un estado inicial y Samadhi, la etapa final en una fase de meditación que lleva a la interiorización profunda y permite transcender la 
                                            experiencia ordinaria.</li>
                                        </ul></div>
                                    break;

                case 2:
                    returnValue = <div><h2>BHAKTI YOGA</h2>
                                    Bhakti significa devoción a un dios o divinidad. La idea fundamental de esta senda es proyectar esta devoción hacia 
                                    una divinidad concreta sin pedir nada a cambio, en forma de entrega absoluta a ella. Podríamos decir que la mayoría de religiones llegan a entenderse
                                    como devocionales.  </div>   
                                    break;              
                case 3:
                    returnValue = <div><h2>JNAN YOGA</h2>
                                    El Jñana Yoga se puede traducir como el sendero del conocimiento y la subiduría. Lo que no se ha experimentado por uno mismo 
                                    no debe creerse y por tanto no puede aceptarse lo implícito; hay que eliminar todo acondicionamiento, hay que desaprender para poder tener acceso al conocimiento
                                    profundo desde la experiencia directa. Un camino a la realización mediante la comprensión gnóstica, que se centra especialmente en una búsqueda del puro conocimiento
                                    de la naturaleza y de lo temporal.</div>
                                    break;

                case 4:
                    returnValue = <div><h2>DHAYANA YOGA</h2>
                                    El Dhayana Yoga es la vía para alcanzar la realizacón mediante la meditación. TODO lo que somos es producto de nuestra mente. 
                                    TODA transformación ha de realizarse en primer lugar a nivel cognitivo en el mundo de las ideas; la mente es la que crea nuestras cadenas ,por lo que es ella la que nos
                                    puede liberar de ellas. Para esto se requiere un adiestramiento, la meditación.</div>
                                    break;

                case 5:
                    returnValue = <div><h2>HATHA YOGA</h2>
                                    Hatha significa sol y luna, y por lo tanto Hatha Yoga representa la unión del sol y la luna, que simbolizan la fuerza vital y la
                                    fuerza mental. Este sendero busca la realización del sí mismo mediante el fortalecimiento del cuerpo, que es mucho más asequible que la mente, para que se 
                                    transmute en cuerpo divino y le permita alcanzar la liberación. El Hatha Yoga se compone de una serie de prácticas, que han de conseguir el despertar de
                                    la Kundalini y llevar al adepto a la supraconciencia. <br/><br/>
                                    Podemos agrupar estas prácticas de la siguiente forma:  <br/><br/>
                                        <ul className="text">
                                            <li>Prácticas de purificación (shatkarmas)</li>
                                            <li>Posturas (asanas)</li>
                                            <li>Llaves energéticas (bhandas)</li>
                                            <li>Sellos o gestos (mudras)</li>
                                            <li>Dominio de la respiración (pranayamas)</li>
                                        </ul>                      
                                    NO hay que confundir el Hatha Yoga con ejercicios de estiramientos o con una actividad deportiva o terapéutica ,
                                    ya que eso sería simplificar mucho su auténtico contenido.</div>
                                    break;            
                                    
                case 6:
                    returnValue = <div><h2>JAPA YOGA</h2>
                                    Japa significa repetir en voz baja e internamente. El Japa Yoga consiste fundamentalmente en la repetición de cualquier mantra
                                    nombre del señor o dios, y se trata de un práctica muy antigua. La recitación debe de seguir una metodología concreta para que el mantra libere todo lo
                                    contenido en él. Dá un especial valor al nombre y otorga mucha importancia a estar atentos al significado mientras se recita el mantra. </div>
                                    break;

                case 7:
                    returnValue = <div><h2>KUNDALINI YOGA</h2>
                                    La Kundalini mora en los Chakras inferiores y el practicante ha de favorecer su ascenso, paso a paso , chakra a chakra, hacia la parte 
                                    superior de la cabeza, al loto del mil pétalos (chakra superior). Cada chakra es un peldaño que hay que ir despertando, hay que seguir la cadena sin saltarse ninguno.
                                    Cuando uno despierta liberándose de residuos e impurezas, permite que la Kundalini ascienda a él. La llegada al último chakra significa la fusión del principio 
                                    femenino con el masculino que habita en el chackra superior. A esto se le llama el despertar de la Kundalini que produce un éxtasis repleto de sensaciones físicas.
                                    Las herramientas son parecidas a las del Hatha Yoga: asanas, pranayamas, mudras, bhandas, pero siempre enfocadas a purificar un chakra en concreto. </div>
                                    break;

                case 8:
                    returnValue = <div><h2>LAYA YOGA</h2>
                                    El Laya Yoga significa disolver o desvanecer las condiciones que limitan. Se trata de una senda tántrica que utiliza técnicas 
                                    psicoenergéticas e iniciaciones para la disolución de las impresiones mentales que se han acumulado con él pasado.
                                    Para romper la relación causa - efecto de la mente , se hace uso de las siguientes herramientas: control de la respiración, mantras (sonidos),
                                    yantras (formas geométricas) y mudras (gestos).</div>
                                    break;

                case 9:
                    returnValue = <div><h2>MANTRA YOGA</h2>
                                    El Mantra Yoga, usa el poder del sonido y la vibración que este emite para purificar la mente y el cuerpo.
                                    Puede ser una palabra, sílaba o frase, cuya pronunciación emite un sonido concreto, como ocurre con el mantra Om (Aum). Está encerrado en su poder creativo y en 
                                    su acción directa sobre la conciencia.</div>
                                    break;

                case 10:
                    returnValue = <div><h2>TANTRA YOGA</h2>
                                    Tantra significa tejido, es una doctrina que apareció en la India en el siglo IV. Combina filosofía, esoterismo y 
                                    espiritualidad. Es el promotor del cambio que sustituye la idea de un cuerpo como carga o lastre por la visión tántrica del cuerpo como morada 
                                    sagrada que hay que cuidar.</div>
                                    break;

                case 11:
                    returnValue = <div><h2>OTRAS SENDAS</h2>
                                    <ul>
                                        <li>Kriya Yoga</li>   
                                        <li>Siddha Yoga</li>  
                                        <li>Samkya Yoga</li>   
                                        <li>Agni Yoga</li>   
                                        <li>Shiva Yoga</li>   
                                        <li>Shakti Yoga</li>   
                                        <li>Yantra Yoga</li>  
                                    </ul> </div>
                                    break;                          

                default:
                    returnValue = <div></div>
                    break;
            }
               
            this.setState({modalContent: returnValue, modal: !this.state.modal});
        }

    render() {
        return (
            <div>                            
                <div className="picture"></div>  
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalBody>
                            {this.state.modalContent}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>Volver</Button>
                        </ModalFooter>
                    </Modal>     
                    <div className="container">
                        <Yoga/>

                <div className="row"> 
                    <div className="gallery">   
                        <div className="col-md-12"></div>                    
                        <div>
                            <img className="picture1" src={poseocean}/>
                        </div>
                        <div className="">
                            <img className="picture1" src={poserocks}/>
                        </div>
                        <div className="">
                            <img className="picture1" src={hands}/>
                        </div>     
                    </div>         
                </div>
                    
                <div className="container row">
                    <h1 className="header col-md-12" id="arbol">El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías.</h1>
                </div>

                     <div className="container row">
                         <div className="col-md-3">
                            
                             <div>                                 
                                <Card className="cardbody">
                                    <CardTitle className="cardtitle" id="karma">KARMA YOGA</CardTitle>
                                        <CardText className="cardtext">
                                            Karma significa acción, pero en general se entiende como los... 
                                            &nbsp;<a href="#karma" onClick={()=>this.toggle(0)}>Leer más</a>
                                    </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="raja">RAJA O ASTHANGA YOGA</CardTitle>
                                        <CardText className="cardtext">Raja Yoga significa yoga real o de los reyes y también...
                                         
                                         &nbsp;<a href="#raja" onClick={()=>this.toggle(1)}>Leer más</a>
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="bhakti">BHAKTI YOGA</CardTitle>
                                        <CardText className="cardtext">Bhakti significa devoción a un dios o divinidad...
                                        &nbsp;<a href="#bhakti" onClick={()=>this.toggle(2)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="jñan">JÑAN YOGA</CardTitle>
                                        <CardText className="cardtext">El Jñana Yoga se puede traducir como el sendero del...
                                        &nbsp;<a href="#jñan" onClick={()=>this.toggle(3)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                             <div>
                                <Card className="cardbody">
                                    <CardTitle className="cardtitle" id="dhayana">DHAYANA YOGA</CardTitle>
                                        <CardText className="cardtext">El Dhayana Yoga es la vía para alcanzar la realizacón mediante... 
                                        &nbsp;<a href="#dhayana" onClick={()=>this.toggle(4)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="hatha">HATHA YOGA</CardTitle>
                                        <CardText className="cardtext">Hatha significa sol y luna, y por lo tanto Hatha Yoga...
                                        &nbsp;<a href="#hatha" onClick={()=>this.toggle(5)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="japa">JAPA YOGA</CardTitle>
                                        <CardText className="cardtext">Japa significa repetir en voz baja e internamente...
                                        &nbsp;<a href="#japa" onClick={()=>this.toggle(6)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="kundalini">KUNDALINI YOGA</CardTitle>
                                        <CardText className="cardtext">La Kundalini mora en los Chakras inferiores y el practicante...
                                            &nbsp;<a href="#kundalini" onClick={()=>this.toggle(7)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody">
                                    <CardTitle className="cardtitle" id="laya">LAYA YOGA</CardTitle>
                                        <CardText className="cardtext">El Laya Yoga significa disolver o desvanecer las condiciones...
                                        &nbsp;<a href="#laya" onClick={()=>this.toggle(8)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="mantra">MANTRA YOGA</CardTitle>
                                        <CardText className="cardtext">El Mantra Yoga, usa el poder del sonido y la vibración que...
                                        &nbsp;<a href="#mantra" onClick={()=>this.toggle(9)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="tantra">TANTRA YOGA</CardTitle>
                                        <CardText className="cardtext">Tantra significa tejido, es una doctrina que apareció...
                                        &nbsp;<a href="#tantra" onClick={()=>this.toggle(10)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <Card className="cardbody1">
                                    <CardTitle className="cardtitle" id="otras">OTRAS SENDAS</CardTitle>
                                        <CardText className="cardtext">
                                            Hay otras sendas...
                                                &nbsp;<a href="#otras" onClick={()=>this.toggle(11)}>Leer más</a>  
                                        </CardText>
                                </Card>
                            </div>
                        </div> 
                </div>  

                <div className="row"> 
                    <div className="gallery">   
                        <div className="col-md-12"></div>                    
                        <div>
                            <img className="picture1" src={back}/>
                        </div>
                        <div className="">
                            <img className="picture1" src={ontree}/>
                        </div>
                        <div className="">
                            <img className="picture1" src={top}/>
                        </div>     
                        {/* <div className="">
                            <img className="picture1" src={poseocean}/>
                        </div>  */}
                    </div>         
                </div>       

                
                <div className="container">
                    <Beneficios/>
                </div>
                <div className="container">
                    <Galeria/>
                </div>


                </div>                          
            
            </div>



        )
    }
}

export default Home;