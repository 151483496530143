import React from "react";
import "./beneficios.css";
import poseocean from "../pictures/poseocean.jpg";
import sundown from "../pictures/sundown.jpg";
import stone_liten from "../pictures/stone_liten.jpg";
import pilates from "../pictures/pilates.png";
import yinyan from "../pictures/yinyan.png";
import sitting from "../pictures/sitting.png";



export class Beneficios extends React.Component {
    render() {
        return (
            <div style={{backgroundImage:{stone_liten}}}>
                <div className="row" >
                    <div className="col-md-12" >
                         <p className="header" id="beneficios">Beneficios del Yoga</p>
                            <p className="headline1">El yoga, con sus diversas prácticas físicas, espirituales y meditativas, tiene numerosos beneficios en nuestro bienestar integral. Es un sistema holístico que
                            trabaja en todos los planos. En el físico, entre otras cosas, nos hace más conscientes de los ritmos y necesidades naturales del cuerpo, fortalece músculos y huesos, 
                            retrasa el proceso de envejecimiento, aumenta la energía vital, da flexibilidad y equilibrio; oxigena y limpia nuestros órganos, beneficia nuestro sistema cardiovascular,
                            endocrino, digestivo y respiratorio; abre los canales energéticos y equilibra los Chakras.
                            En el plano mental disminuye el estrés, favorece la concentración, la capacidad de razonamiento y nos levanta el ánimo. También nos trae armonía interior, 
                            que muchas veces nos ayuda a dormir mejor, disminuye la tendencia a reaccionar con rabia o tristeza ante las situaciones, ayuda a la superación de miedos y tener
                            un pensamiento positivo. El equilibrio interior y la apertura de corazón repercuten no sólo en una mejor forma de relacionarse con el mundo, y una mejor
                            experiencia vital, sino también en la salud física.</p>
                    </div>
                    {/* <div className="col-md-4">
                        <img className="picture" src={poseocean}/>
                    </div>  */}
                </div>

                <div className="row">
                    <div className="col-md-12">                
                        <p className="headline">Beneficios Físicos y Mentales</p>
                        <ul className="text1">
                            <li>En general, el cuerpo tiene mayor energía, está libre de toxinas, recupera su capacidad de sanar naturalmente, está más fuerte ante enfermedades y <br/>
                            estímulos externos y funciona de una forma óptima en todos sus elementos.</li>
                            <li>Aumenta la flexibilidad en articulaciones y músculos. Nos hace más flexibles, vitales y ágiles.</li>
                            <li>Aumenta la fortaleza física, trabaja los músculos y moldea el cuerpo.</li>
                        </ul>                       
                       

                        <p className="headline">Beneficios espirituales</p>
                        <ul className="text1">
                            <li>Sabiduría: una práctica persistente desarrolla el conocimiento que florece como sabiduría</li>
                            <li>Libertad: la ecuanimidad y la sabiduría conducen a la experiencia de libertad en la vida diaria.</li>                       
                        </ul>
                    </div>
                </div>

                <div className="row"> 
                    <div className="gallery">   
                        <div className="col-md-12"></div>                    
                        <div>
                            <img className="picture2" src={pilates}/>
                        </div>
                        <div className="">
                            <img className="picture2" src={yinyan}/>
                        </div>
                        <div className="">
                            <img className="picture2" src={sitting}/>
                        </div>     
                    </div>         
                </div>

                <div >
                    <div className="row">
                        <div className="col-md-12">
                            <p className="headline">Los Yoga Sutras afirman: "el yoga nos hace atractivos, bellos, firmes y fuertes"</p>
                                <ul className="text">
                                    <li>Aumenta la energía y la vitalidad. Las asanas y otras prácticas de yoga aumentan en nosotros el prana, o energía vital</li>
                                    <li>Es una medicina preventiva: estimula los procesos metabólicos y anabólicos, mejora la circulación de energía, oxigena el cuerpo</li>
                                    <li>Ayuda a disminuir el dolor causado por ciertas condiciones físicas, a través de la producción de endorfinas, la relajación y la disminución de la inflamación.</li>
                                    <li>Nos enseña a respirar mejor, lo cual aumenta la salud en nuestros órganos, nuestra energía y nos da calma interior</li>
                                    <li>Mantiene la columna vertebral joven, la postura recta, y evita el deterioro de los discos, a través de la irrigación sanguínea y la flexibilidad</li>
                                    <li>Ayuda a disminuir el envejecimiento: a través de la salud de la columna vertebral, evitando el estrés, contrarrestando los procesos catabólicos de deterioro
                                    de las células ayudando a limpiar el cuerpo de toxinas que producen un envejecimiento artificial del cuerpo, y manteniendo las partes del cuerpo lubricadas y 
                                    limpias</li>
                                    <li>Estimula los órganos internos a través de ejercicios específicos. Uno de sus efectos más notorios es en el sistema endocrino y neurológico</li>
                                    <li>Trabaja el sistema inmunológico, el sistema digestivo, el sistema respiratorio</li>
                                    <li>Puede disminuir los riesgos cardiovasculares como la presión sanguínea elevada, los niveles de azúcar y colesterol en la sangre, y puede elevar la producción
                                    de antioxidantes.</li>
                                    <li>Limpia y desintoxica. A través de la respiración, la piel y el adecuado funcionamiento de los medios de limpieza, el yoga nos permite deshacernos de 
                                    toxinas y renovar el oxígeno</li>
                                    <li>Las posturas trabajan los diferentes chakras que se relacionan con el cuerpo físico y aspectos de nuestros ser</li>
                                    <li>Mejora la vida sexual: aumenta las hormonas sexuales y genera estímulos mentales asociados a la excitación sexual, además de favorecer la satisfacción en las 
                                    relaciones de pareja en todo sentido</li>
                                    <li>Nos hace sentir menos cansados porque no desperdiciamos energía en tensiones innecesarias</li>
                                    <li>Una práctica de yoga meditativa y consciente calma la mente y la hace más aguda, haciendo que estemos emocionalmente y mentalmente más estables
                                    y fuertes. Sin embargo, se recomienda que el yoga sea complementado con una práctica regular de meditación</li>
                                    <li>Puede ayudar a combatir la depresión y a dar un sentido de propósito</li>
                                    <li>Calma la respuesta fight or flight, por lo que el temor o la ira disminuyen</li>
                                    <li>Nos hace más resistentes al estrés y nos ayuda a manejar la ansiedad, además de despejar la mente y traer la atención al momento presente haciendo la 
                                    visión más clara</li>
                                    <li>Ayuda a dormir mejor</li>
                                    <li>Facilita la concentración, a estar alerta y enfocarse</li>
                                    <li>Da claridad mental y facilita la toma de decisiones</li>
                                    <li>Eleva las capacidades intelectuales</li>
                                    <li>Contribuye a un pensamiento positivo, a la relación armoniosa con el entorno y al entusiasmo por la vida</li>
                                    <li>Aumenta la autoestima y la capacidad de creer en nosotros mismos</li>
                                    <li>Mejora la relación con nuestro ser físico y nuestra aceptación de él.</li>
                                </ul>
                        </div>
                        </div>
                    
                        {/* <div className="col-md-6">
                            <p className="headline"></p> 
                                 <ul className="text">
                                    <li>Las posturas trabajan los diferentes chakras que se relacionan con el cuerpo físico y aspectos de nuestros ser</li>
                                    <li>Mejora la vida sexual: aumenta las hormonas sexuales y genera estímulos mentales asociados a la excitación sexual, además de favorecer la satisfacción en las 
                                    relaciones de pareja en todo sentido</li>
                                    <li>Nos hace sentir menos cansados porque no desperdiciamos energía en tensiones innecesarias</li>
                                    <li>Una práctica de yoga meditativa y consciente calma la mente y la hace más aguda, haciendo que estemos emocionalmente y mentalmente más estables
                                    y fuertes. Sin embargo, se recomienda que el yoga sea complementado con una práctica regular de meditación</li>
                                    <li>Puede ayudar a combatir la depresión y a dar un sentido de propósito</li>
                                    <li>Calma la respuesta fight or flight, por lo que el temor o la ira disminuyen</li>
                                    <li>Nos hace más resistentes al estrés y nos ayuda a manejar la ansiedad, además de despejar la mente y traer la atención al momento presente haciendo la 
                                    visión más clara</li>
                                    <li>Ayuda a dormir mejor</li>
                                    <li>Facilita la concentración, a estar alerta y enfocarse</li>
                                    <li>Da claridad mental y facilita la toma de decisiones</li>
                                    <li>Eleva las capacidades intelectuales</li>
                                    <li>Contribuye a un pensamiento positivo, a la relación armoniosa con el entorno y al entusiasmo por la vida</li>
                                    <li>Aumenta la autoestima y la capacidad de creer en nosotros mismos</li>
                                    <li>Mejora la relación con nuestro ser físico y nuestra aceptación de él.</li>
                                </ul>         
                        </div>  */}
                    </div>          
                </div>
        )
    }
}

export default Beneficios;

           