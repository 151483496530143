import React from "react";
import './laya.css';
import legs from "../../pictures/legs.jpg";


export class Laya extends React.Component {
    render() {
        return(
            <div>        
                <div className="row">
                    <div className="col-md-7">
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">LAYA YOGA</p>
                                <p className="text">El Laya Yoga significa disolver o desvanecer las condiciones que limitan. Se trata de una senda tántrica que utiliza técnicas 
                                psicoenergéticas e iniciaciones para la disolución de las impresiones mentales que se han acumulado con él pasado.
                                Para romper la relación causa - efecto de la mente , se hace uso de las siguientes herramientas: control de la respiración, mantras (sonidos),
                                yantras (formas geométricas) y mudras (gestos).</p>    
                </div>       

                <div className="col-md-1"></div> 

                <div className="col-md-4">
                    <img className="picture" src={legs}/>
                </div> 
             </div>
        </div> 
        
        )
    }
}

export default Laya;