import React from "react";
import './dhayana.css';
import top from "../../pictures/top.jpg";


export class Dhayana extends React.Component {
    render() {
        return(            
            <div>
                <div className="row">
                    <div className="col-md-7">        
                         <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">DHAYANA YOGA</p>
                                <p className="text">El Dhayana Yoga es la vía para alcanzar la realizacón mediante la meditación. TODO lo que somos es producto de nuestra mente. 
                                TODA transformación ha de realizarse en primer lugar a nivel cognitivo en el mundo de las ideas; la mente es la que crea nuestras cadenas ,por lo que es ella la que nos
                                puede liberar de ellas. Para esto se requiere un adiestramiento, la meditación.</p>
                     </div>

                    <div className="col-md-1"></div>
                
            <div className="col-md-4">
                    <img className="picture" src={top}/>
                </div>
            </div>
            </div>
        )
    }
}

export default Dhayana;