import React from "react";
import './otras.css';
import rock1 from "../../pictures/rock1.png";


export class Otras extends React.Component {
    render() {
        return(
            <div>        
                <div className="row">
                    <div className=" col-md-7">
                        <p className="header1">"El árbol del Yoga tiene muchas sendas cuyos orígenes surgieron en la antigüedad desarrollando nuevas filosofías."</p> <br/>
                            <p className="headline">OTRAS SENDAS</p>
                                <p className="text">
                                    <ul>
                                        <li>Kriya Yoga</li>   
                                        <li>Siddha Yoga</li>  
                                        <li>Samkya Yoga</li>   
                                        <li>Agni Yoga</li>   
                                        <li>Shiva Yoga</li>   
                                        <li>Shakti Yoga</li>   
                                        <li>Yantra Yoga</li>  
                                    </ul>
                                </p>
                        <p className="text special"># SOBRE ESTOS TEMAS IRÉ PROFUNDIZÁNDO MÁS A LO LARGO DEL BLOG#</p>    
                    </div> 

                    <div className="col-md-1"></div>                   
                    
                     <div className="col-md-4">
                    <img className="picture" src={rock1}/>
                </div>  
             </div>
        </div>
        )
    }
}

export default Otras;