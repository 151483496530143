import React from "react";
import "./navBar.css";
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText, Link} from 'reactstrap';
import logo_NY from "../pictures/logo_NY.png";


export class NavBar extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            isOpen: false,
            collapsed: true,
        };
      };

      toggleNavbar () {
          this.setState({
            isOpen: !this.state.isOpen
          });
      };

    render() {
        return(
            <div className="">
            <Navbar color="" light expand="md">
            <NavbarBrand href="/"><img className="logo" src={logo_NY}/></NavbarBrand>

                <NavbarToggler onClick={this.toggleNavbar} />
                <Collapse isOpen={this.state.isOpen} navbar>   
                <Nav className="ms-auto" navbar >   
                    <NavItem>
                        <NavLink href="#yoga">Yoga by Sara Gonzalez</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>                    
                        {/* <DropdownToggle nav caret> */}
                        <NavItem>
                            <NavLink href="#arbol">El arbol del Yoga</NavLink>
                        </NavItem>
                        {/* </DropdownToggle>                         */}
                        <DropdownMenu right>
                            <DropdownItem>
                                <a href="./karma" className="drops">Karma Yoga</a>
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./raja" className="drops">Raja O Asthanga Yoga</a>
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./bhakti" className="drops">Bhakti Yoga</a>
                             </DropdownItem>
                            <DropdownItem>
                               <a href="./jñana" className="drops">Jñana YOGA</a>
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./dhayana" className="drops">Dhayana Yoga</a>                            
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./hatha" className="drops">Hatha Yoga</a>                            
                            </DropdownItem>
                            <DropdownItem>
                               <a href="./japa" className="drops">Japa Yoga</a>                            
                            </DropdownItem>
                            <DropdownItem>
                               <a href="./kundalini" className="drops">Kundalini Yoga</a>                            
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./laya" className="drops">Laya Yoga</a>                            
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./mantra" className="drops">Mantra Yoga</a>                         
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./tantra" className="drops">Tantra Yoga</a>                            
                            </DropdownItem>
                            <DropdownItem>
                                <a href="./otras" className="drops">Otras sendas</a>                            
                            </DropdownItem>                           
                        </DropdownMenu>
                    </UncontrolledDropdown>                    
                    <NavItem>
                        <NavLink href="#beneficios">Beneficios del Yoga</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#galeria">Galeria</NavLink>
                    </NavItem>        
                   
                                       
                </Nav>
                </Collapse>
            </Navbar>
     </div> 

        )
    }
}

export default NavBar;